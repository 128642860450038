/**
 * UI Effects Library
 */

/**
 * Reveal inner content on hover
 *
 * Courtesy of Paul Lewis, then tweaked for our purposes
 * https://www.youtube.com/watch?v=YdvQPkJd0gc
 */
export function circularReveal() {
  const elements = document.querySelectorAll('.circular-reveal');

  for (const element of elements) {
    const inner = element.querySelector('.circular-reveal__content');

    const easing = 0.3;
    const outScale = 0.85;
    const inScale = 1;
    let targetScale = outScale;
    let elementScale = targetScale;
    let innerScale = 1 / elementScale;

    ['pointerover', 'focusin'].map((name) =>
      element.addEventListener(name, () => {
        targetScale = inScale;
      }),
    );

    ['pointerout', 'focusout'].map((name) =>
      element.addEventListener(name, () => {
        //Maintain hover effect if focused;
        if (document.activeElement === element) {
          return;
        }

        targetScale = outScale;
      }),
    );

    const update = () => {
      elementScale += (targetScale - elementScale) * easing;
      innerScale = 1 / elementScale;

      element.style.transform = `scale(${elementScale})`;
      inner.style.transform = `scale(${innerScale})`;

      requestAnimationFrame(update);
    };

    requestAnimationFrame(update);
  }
}

function effectsInit() {
  circularReveal();
}

export default effectsInit;

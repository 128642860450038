import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock';

function init() {
  const doc = document.documentElement;
  const hamburger = document.querySelector('.siteNav-hamburger');
  const nav = document.querySelector('.mobile-nav-inner');
  const mobileSubNavs = document
    .getElementById('primaryMenuMobile')
    .querySelectorAll('.menu-item-has-children');
  const overlay = document.querySelector('.mobile-nav-container');

  let mobileNavIsOpen = false;
  let resizeTimer;

  function toggleSubNavClass(el) {
    if (!el.classList.contains('is-visible')) {
      el.classList.add('is-visible');
      el.style.height = 'auto';

      const height = `${el.scrollHeight}px`;
      el.style.maxHeight = height;
    } else {
      el.style.maxHeight = '0px';

      el.addEventListener(
        'transitionend',
        () => {
          el.classList.remove('is-visible');
        },
        {
          once: true,
        },
      );
    }
  }

  // Toggle a body class which opens or closes the mobile nav
  function toggleClass(force) {
    if (!doc.classList.contains('mobile-nav-open') && force !== 'close') {
      doc.classList.add('mobile-nav-open');
      disableBodyScroll(nav);
      mobileNavIsOpen = true;
    } else {
      doc.classList.remove('mobile-nav-open');
      mobileNavIsOpen = false;
      enableBodyScroll(nav);
      clearAllBodyScrollLocks();
    }
  }

  // Events
  hamburger.onclick = (e) => {
    e.preventDefault();
    toggleClass();
  };

  document.onkeydown = (e) => {
    if (!mobileNavIsOpen) return;

    e = e || window.event;
    let isEscape = false;

    if ('key' in e) {
      isEscape = e.key === 'Escape' || e.key === 'Esc';
    } else {
      isEscape = e.keyCode === 27;
    }

    if (isEscape) {
      toggleClass('close');
    }
  };

  window.onresize = () => {
    if (!mobileNavIsOpen) return;

    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(() => {
      toggleClass('close');
    }, 250);
  };

  // Close the mobile nav if anything is clicked outside of the mobile-nav-inner
  overlay.onclick = (e) => {
    if (e.target === overlay) {
      toggleClass('close');
    }
  };

  for (let index = 0; index < mobileSubNavs.length; index++) {
    const link = mobileSubNavs[index].querySelector('a');
    const subnav = mobileSubNavs[index].querySelector('.sub-menu');
    const subnavItems = subnav.querySelectorAll('a');

    link.addEventListener('click', (e) => {
      e.preventDefault();
      toggleSubNavClass(subnav);
    });

    for (let index = 0; index < subnavItems.length; index++) {
      if (subnavItems[index].anchor !== '') {
        // If the link is an anchor to the current page, close the nav
        subnavItems[index].addEventListener('click', () => {
          toggleClass();
        });
      }

      // Check if the href has an anchor
      if (subnavItems[index].href.indexOf('#') === -1) {
        continue;
      }

      // Parse the href and store the link without the anchor
      const linkHref = subnavItems[index].href.split('#')[0];
      const windowHref = window.location.href.split('#')[0];

      // If the link href (minus the anchor) is the current page, remove the .current-menu-item class from the parent li
      if (linkHref === windowHref) {
        subnavItems[index].parentElement.classList.remove(
          'current-menu-item',
        );
      }
    }
  }
}

export default init;
